import React from 'react';
import SampleEditor from './SampleEditor';
import './App.css';
import logo from './logo.svg';
import './firebase';

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img className="tasquet-logo" src={logo} alt="Tasquet" aria-hidden />
      </header>

      <div className="editor-container">
        <SampleEditor />
      </div>
    </div>
  );
};

export default App;
