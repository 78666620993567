/** @jsx jsx */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { jsx } from '@emotion/core';
import { Node, createEditor, Editor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory, HistoryEditor } from 'slate-history';
import {
  withMarkdown,
  decorate,
  renderers,
  useOnCopy,
  useOnCut,
  useOnKeyDown,
} from '@tasquet/slate-markdown-plugin';

const initialText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

---

This is an *__absolutely__ important* ~~text~~ and \`code\`

An auto link to https://google.com
and
[GitHub](https://github.com)

Images are previewed inline: ![Tasquet Logo](https://sample.tasquet.app/logo.svg)

Links to Youtube videos are also previewed inline: [Tasquet introduction](https://www.youtube.com/watch?v=zYCL2L0RjH0).

# ATX Heading 1
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum

## ATX Heading 2

### ATX Heading 3

Setext Heading 1
===

Setext Heading 2
---

* List
  * Nested list
  * [ ] Checkbox
  * [x] Checkbox
  * [ ] A **decorated** text in a list item

\`\`\`
code block
code block
code block
\`\`\`

> Quote
> Quote
> Quote
> > Nested quote
> > Nested quote

`;

const SampleEditor: React.FC = () => {
  const [value, setValue] = useState<Node[]>([]);
  const editor = useMemo(
    () => withMarkdown(withHistory(withReact(createEditor()))),
    []
  );
  const onChange: React.ComponentProps<typeof Slate>['onChange'] = useCallback(
    (value) => {
      if (editor.operations.every((op) => op.type === 'set_selection')) {
        return;
      }
      setValue(value);
    },
    [editor]
  );
  const onCopy = useOnCopy(editor);
  const onCut = useOnCut(editor);
  const onKeyDown = useOnKeyDown(editor);

  useEffect(() => {
    const newValue: Node[] = [
      {
        type: 'paragraph',
        children: initialText.split('\n').map(
          (line): Node => ({
            type: 'line',
            children: [
              {
                text: line,
              },
            ],
          })
        ),
      },
    ];
    setValue(newValue);
    setTimeout(() => {
      HistoryEditor.withoutSaving(editor, () => {
        Editor.normalize(editor, { force: true });
      });
    });
  }, [editor]);

  return (
    <Slate editor={editor} value={value} onChange={onChange}>
      <renderers.MarkdownEditorProvider>
        <Editable
          decorate={decorate}
          renderElement={renderers.renderElement}
          renderLeaf={renderers.renderLeaf}
          onCopy={onCopy}
          onCut={onCut}
          onKeyDown={onKeyDown}
          css={renderers.editableStyle}
        />
      </renderers.MarkdownEditorProvider>
    </Slate>
  );
};

export default SampleEditor;
